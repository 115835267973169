const ContactUs = () => {
  return (
    <>
      <section className="text-gray-600 body-font relative">
        <div className="container px-5 mx-auto flex sm:flex-nowrap flex-wrap">
          <div className="lg:w-full h-96 bg-gray-300 rounded-lg overflow-hidden p-10 flex items-end justify-start relative">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.2879151606735!2d55.3706726747236!3d25.260898377669967!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa4277035b79246f5%3A0x81be8828beb16289!2sBest%20Global%20DISTRIBUTION%20FZCO!5e0!3m2!1sen!2sin!4v1693687304469!5m2!1sen!2sin" width="100%" height="100%" className="absolute inset-0" frameborder="0" title="map" marginheight="0" marginwidth="0" scrolling="no" referrerpolicy="no-referrer-when-downgrade"></iframe>
            {/*<iframe width="100%" height="100%" className="absolute inset-0" frameborder="0" title="map" marginheight="0" marginwidth="0" scrolling="no" src="https://maps.google.com/maps?width=100%&height=600&hl=en&q=%C4%B0zmir+(My%20Business%20Name)&ie=UTF8&t=&z=14&iwloc=B&output=embed" style={{filter: ' opacity(0.9)'}}></iframe>*/}
            <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
              <div className="lg:w-1/2 px-6">
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">ADDRESS</h2>
                <p className="mt-1">LIU F04, Warehouse, Dubai Airport Free Zone, Dubai U.A.E.</p>
              </div>
              <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">EMAIL</h2>
                <a className="text-indigo-500 leading-relaxed" href="mailto:sales@bgduae.com">sales@bgduae.com</a>
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">PHONE</h2>
                <a className="leading-relaxed" href="tel:+97142985101" >+97142985101</a>
              </div>
            </div>
          </div>
          
        </div>
      </section>
    </>
  );
}

export default ContactUs;
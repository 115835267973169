import ContactUs from '../components/ContactUs'

const ContactScreen = () => {
  return (
    <>
      <div className="home_about_us">
        <div className="section_heading">
          <h1 className="section_heading_text2">Contact us</h1>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-24">
        <ContactUs />
      </div>
    </>
  );
}

export default ContactScreen;

const Footer = () => {
  return (
    <>
      <div className="relative isolate overflow-hidden bg-gray-900 py-16 sm:py-24 lg:py-24 mt-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 lg:max-w-none lg:grid-cols-2">
          <div className="max-w-xl lg:max-w-lg">
            <h2 className="mt-4 font-semibold text-white">We're good with number</h2>
            <div class="pt-6">
              <div class="mx-auto max-w-7xl">
                <dl class="grid grid-cols-1 gap-x-2 gap-y-4 lg:grid-cols-3">
                  <div class="flex max-w-xs flex-col">
                    <dt class="text-sm leading-7 text-white">Year of Experience</dt>
                    <dd class="order-first text-xl font-semibold tracking-tight text-white sm:text-lg">10+</dd>
                  </div>
                  <div class="flex max-w-xs flex-col">
                    <dt class="text-sm leading-7 text-white">Brands</dt>
                    <dd class="order-first text-xl font-semibold tracking-tight text-white sm:text-lg">30+</dd>
                  </div>
                  <div class="flex max-w-xs flex-col">
                    <dt class="text-sm leading-7 text-white">Intl. Partners</dt>
                    <dd class="order-first text-xl font-semibold tracking-tight text-white sm:text-lg">100+</dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
          <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
            <div className="flex flex-col items-start">
              <dt className="mt-4 font-semibold text-white">Category</dt>
              <dd className="mt-2 leading-7 text-gray-400">
                <a href="/"> Home </a>
              </dd>
              <dd className="mt-2 leading-7 text-gray-400">
                <a href="/about"> About us </a>
              </dd>
              <dd className="mt-2 leading-7 text-gray-400">
                <a href="/contact" > Contact us </a>
              </dd>
            </div>
            <div className="flex flex-col items-start">
              
              <dt className="mt-4 font-semibold text-white">Contact us</dt>
              <dd className="mt-2 leading-7 text-gray-400">
                LIU F04, Warehouse, Dubai Airport Free Zone, Dubai U.A.E.
              </dd>
              <dd className="mt-2 leading-7 text-gray-400">
                <a href="mailto:sales@bgduae.com">sales@bgduae.com</a>
              </dd>
              <dd className="mt-2 leading-7 text-gray-400">
                <a href="tel:+97142985101" >+97142985101</a>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6" aria-hidden="true">
        <div
          className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </div>
    </>
  );
}

export default Footer;
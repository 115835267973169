import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay'
import img1 from '../assets/image/ban1.jpg'
import img2 from '../assets/image/ban2.jpg'
import img3 from '../assets/image/ban5.jpg'
import img4 from '../assets/image/ban4.jpg'


export default function Banner () {
  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      breakpoints={{
          374: {
            navigation: {
              enabled: false,
            },
          },
          480: {
            navigation: {
              enabled: false,
            },
          },
          768: {
            navigation: {
              enabled: true,
            },
          },
        }}
      
    >
      <SwiperSlide>
        <div className="banner_top">
          {/*<div className="banner_heading">
            <h1 className="text-dark banner_heading_text">Best Global distribution</h1>
            <p className="banner_heading_p text-dark" >Established in the year 2017, Best Global Distribution FZCO has grown into one of the leading global traders of Mobile phones, IT equipment, Digital Cameras & Consumer Electronics, Laptops & Gaming devices</p>
          </div>*/}
          <div>
            <img src={img1} alt="image1" className="banner_img" />
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="banner_top">
          
          <div>
            <img src={img2} alt="image1" className="banner_img" />
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="banner_top">
          
          <div>
            <img src={img3} alt="image1" className="banner_img" />
          </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="banner_top">
          
          <div>
            <img src={img4} alt="image1" className="banner_img" />
          </div>
          </div>
      </SwiperSlide>
    </Swiper>
  );
};
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import brand from '../assets/logo/brand.jpeg'
import brand1 from '../assets/logo/brand1.png'
import brand2 from '../assets/logo/brand2.png'
import brand3 from '../assets/logo/brand3.png'
import brand4 from '../assets/logo/brand4.png'
import brand5 from '../assets/logo/brand5.png'
import brand6 from '../assets/logo/brand6.png'
import brand7 from '../assets/logo/brand7.png'
import brand8 from '../assets/logo/brand8.png'
import brand9 from '../assets/logo/brand9.png'
import brand10 from '../assets/logo/brand10.png'
import brand11 from '../assets/logo/brand11.png'
import brand12 from '../assets/logo/brand12.png'
import brand13 from '../assets/logo/brand13.png'
import brand14 from '../assets/logo/brand14.png'
import brand15 from '../assets/logo/brand15.png'


const Brand = () => {
  return (
    <>
      <Swiper
       modules={[Autoplay]}
      spaceBetween={20}
      slidesPerView={8}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
       breakpoints={{
          374: {
            slidesPerView: 2,
          },
          480: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 6,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 8,
            spaceBetween: 50,
          },
        }}
    >
      <SwiperSlide>
          <div>
            <img src={brand} alt="brand" className="brand_pic" />
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div>
            <img src={brand1} alt="brand" className="brand_pic" />
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div>
            <img src={brand2} alt="brand" className="brand_pic" />
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div>
            <img src={brand3} alt="brand" className="brand_pic" />
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div>
            <img src={brand4} alt="brand" className="brand_pic" />
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div>
            <img src={brand5} alt="brand" className="brand_pic" />
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div>
            <img src={brand6} alt="brand" className="brand_pic" />
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div>
            <img src={brand7} alt="brand" className="brand_pic" />
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div>
            <img src={brand8} alt="brand" className="brand_pic" />
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div>
            <img src={brand9} alt="brand" className="brand_pic" />
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div>
            <img src={brand10} alt="brand" className="brand_pic" />
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div>
            <img src={brand11} alt="brand" className="brand_pic" />
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div>
            <img src={brand12} alt="brand" className="brand_pic" />
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div>
            <img src={brand13} alt="brand" className="brand_pic" />
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div>
            <img src={brand14} alt="brand" className="brand_pic" />
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div>
            <img src={brand15} alt="brand" className="brand_pic" />
          </div>
      </SwiperSlide>
    </Swiper>
    </>
  );
}

export default Brand;
import Banner from '../components/Banner'
import Brand from '../components/Brand'
import ContactUs from '../components/ContactUs'

import logo1 from '../assets/image/logo1.jpeg'
import logo2 from '../assets/image/logo2.jpeg'
import logo3 from '../assets/image/logo3.jpeg'
import logo4 from '../assets/image/logo4.jpeg'
import product1 from '../assets/image/product1.png'
import product from '../assets/image/product.jpg'
import product2 from '../assets/image/product2.jpg'
import product3 from '../assets/image/product3.png'
import product4 from '../assets/image/product4.png'
import product5 from '../assets/image/product5.jpg'

const HomeScreen = () => {
  return (
    <div>
      <div className="banner_top_1">
        <Banner />
      </div>
      <div className="home_about_us">
        <div className="section_heading">
          <h1 className="section_heading_text1">We Achieving newer heights every day!</h1>
        </div>
        <div className="home_about_us_pic">
          <div className="home_about_us_pic_inner">
            <p className="home_about_us_text">Established in the year 2017, Best Global Distribution FZCO has grown into one of the leading global traders of Mobile phones, IT equipment, Digital Cameras & Consumer Electronics, Laptops & Gaming devices. Our experience and in-depth market understanding, coupled with our immaculate abilities in sourcing, stocking, competitive pricing and logistics - has helped us provide immense value to our customers. </p>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
       <div className="section_heading">
         <h1 className="section_heading_text">What We Believe</h1>
       </div>
        <div className="home_service">
          <div className="home_service1">
            <div className="home_service2">
              <div className="home_service1_image">
                <img src={logo1} alt="icon"className="serviceimg" />
              </div>
              <div className="home_service1_text">
                <h6 className="home_service1_heading">Vision</h6>
                <p className="home_service1_p">Our vision is to be one of the leading consumer electronics distributors in the global market</p>
              </div>
            </div>
          </div>
          <div className="home_service1">
            <div className="home_service2">
              <div className="home_service1_image">
                <img src={logo2} alt="icon" className="serviceimg" />
              </div>
              <div className="home_service1_text">
                <h6 className="home_service1_heading">Mission</h6>
                <p className="home_service1_p">We strive to be a leading global distributor of advanced technology products, backed by strategic partner networks, a skilled team and valued customers.</p>
              </div>
            </div>
          </div>
          <div className="home_service1">
            <div className="home_service2">
              <div className="home_service1_image">
                <img src={logo3} alt="icon" className="serviceimg" />
              </div>
              <div className="home_service1_text">
                <h6 className="home_service1_heading">Belief</h6>
                <p className="home_service1_p">We believe in providing the best of its services by keeping its customer satisfaction as a prior element.</p>
              </div>
            </div>
          </div>
          <div className="home_service1">
            <div className="home_service2">
              <div className="home_service1_image">
                <img src={logo4} alt="icon" className="serviceimg"/>
              </div>
              <div className="home_service1_text">
                <h6 className="home_service1_heading">Global Presence</h6>
                <p className="home_service1_p">Our valued and satisfied customers are present across the globe, like Europe, Asia, Latin America, Middle East and Africa. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="section_heading">
          <h1 className="section_heading_text">Brand We Deal In</h1>
        </div>
        <div className="brand_head"> <Brand /> </div>
      </div>
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="section_heading">
          <h1 className="section_heading_text">Our Products</h1>
        </div>
        <div>
          <section class="text-gray-600 body-font mt-10 mb-10">
            <div className="container px-5 mx-auto">
              <div className="flex flex-wrap -m-4 respon_product">
                <div className="lg:w-1/3 sm:w-1/2 p-4">
                  <div className="flex relative">
                    <img alt="gallery" className=" inset-0 w-full h-56 object-cover object-center" src={product1} />
                  </div>
                </div>
                <div className="lg:w-1/3 sm:w-1/2 p-4">
                  <div className="flex relative">
                    <img alt="gallery" className=" inset-0 w-full h-56 object-cover object-center" src={product} />
                  </div>
                </div>
                <div className="lg:w-1/3 sm:w-1/2 p-4">
                  <div className="flex relative">
                    <img alt="gallery" className=" inset-0 w-full h-56 object-cover object-center" src={product2} />
                  </div>
                </div>
                <div className="lg:w-1/3 sm:w-1/2 p-4">
                  <div className="flex relative">
                    <img alt="gallery" className=" inset-0 w-full h-56 object-cover object-center" src={product3} />
                  </div>
                </div>
                <div className="lg:w-1/3 sm:w-1/2 p-4">
                  <div className="flex relative">
                    <img alt="gallery" className=" inset-0 w-full h-56 object-cover object-center" src={product4} />
                  </div>
                </div>
                <div className="lg:w-1/3 sm:w-1/2 p-4">
                  <div className="flex relative">
                    <img alt="gallery" className=" inset-0 w-full h-56 object-cover object-center" src={product5} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="section_heading">
          <h1 className="section_heading_text">Contact us</h1>
        </div>
        <div>
          <ContactUs />
        </div>
      </div>
    </div>
  );
}

export default HomeScreen;
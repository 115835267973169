import product1 from '../assets/image/product1.png'
import logo1 from '../assets/image/logo1.jpeg'
import logo2 from '../assets/image/logo2.jpeg'
import logo3 from '../assets/image/logo3.jpeg'
import logo4 from '../assets/image/logo4.jpeg'

const AboutScreen = () => {
  return (
    <>
      <div className="home_about_us">
        <div className="section_heading">
          <h1 className="section_heading_text2">About us</h1>
        </div>
      </div>
      <section className="text-gray-600 body-font">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <img className="object-cover object-center rounded" alt="hero" src={product1} />
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900"> Best Global Distribution FZCO</h1>
            <p className="mb-8 leading-relaxed">Established in the year 2017, Best Global Distribution FZCO has grown into one of the leading global traders of Mobile phones, IT equipment, Digital Cameras & Consumer Electronics, Laptops & Gaming devices. Our experience and in-depth market understanding, coupled with our immaculate abilities in sourcing, stocking, competitive pricing and logistics - has helped us provide immense value to our customers.</p>
          </div>
        </div>
      </section>
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
       <div className="section_heading">
         <h1 className="section_heading_text">What We Believe</h1>
       </div>
        <div className="home_service">
          <div className="home_service1">
            <div className="home_service2">
              <div className="home_service1_image">
                <img src={logo1} alt="icon"className="serviceimg" />
              </div>
              <div className="home_service1_text">
                <h6 className="home_service1_heading">Vision</h6>
                <p className="home_service1_p">Our vision is to be one of the leading consumer electronics distributors in the global market</p>
              </div>
            </div>
          </div>
          <div className="home_service1">
            <div className="home_service2">
              <div className="home_service1_image">
                <img src={logo2} alt="icon" className="serviceimg" />
              </div>
              <div className="home_service1_text">
                <h6 className="home_service1_heading">Mission</h6>
                <p className="home_service1_p">We strive to be a leading global distributor of advanced technology products, backed by strategic partner networks, a skilled team and valued customers.</p>
              </div>
            </div>
          </div>
          <div className="home_service1">
            <div className="home_service2">
              <div className="home_service1_image">
                <img src={logo3} alt="icon" className="serviceimg" />
              </div>
              <div className="home_service1_text">
                <h6 className="home_service1_heading">Belief</h6>
                <p className="home_service1_p">We believe in providing the best of its services by keeping its customer satisfaction as a prior element.</p>
              </div>
            </div>
          </div>
          <div className="home_service1">
            <div className="home_service2">
              <div className="home_service1_image">
                <img src={logo4} alt="icon" className="serviceimg"/>
              </div>
              <div className="home_service1_text">
                <h6 className="home_service1_heading">Global Presence</h6>
                <p className="home_service1_p">Our valued and satisfied customers are present across the globe, like Europe, Asia, Latin America, Middle East and Africa. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutScreen;